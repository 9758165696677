@import "@styles/mixins";
@import "@styles/variables";

.textContainer {
  @include webkit-line-clamp(unset);

  @include mobile-all {
    &.isCollapsed {
      @include webkit-line-clamp(10);
    }
  }
}

.buttonContainer {
  display: none;

  @include mobile-all {
    display: flex;

    &.isHidden {
      display: none;
    }
  }
}

.button {
  color: var(--colorV2-black);
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2.4px;
  transition: color 300ms ease;

  &:hover {
    color: var(--colorV2-dark-green);
    cursor: pointer;
  }
}
