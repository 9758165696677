@import "@styles/variables";

.availabilityCarouselContainer {
  position: relative;
  color: var(--colorV2-black);
}

.carousel {
  min-width: 0;
  width: 100%;
}

.carouselContent {
  gap: 0 !important;
}

.carouselItem {
  flex-basis: 33.33% !important;
}

.carouselPreviousButton,
.carouselNextButton {
  position: absolute;
  top: 65px;
  z-index: 2;
  padding: 0;
  margin: 0;
}

.carouselPreviousButton {
  left: 15px;
}

.carouselNextButton {
  right: 15px;
}
