@import "@styles/variables";
@import "@styles/mixins";

.container {
  font-size: var(--font-size-18);
  line-height: var(--line-height-4);
  font-weight: 400;
  color: var(--colorV2-black);
  white-space: pre-line; // Preserve line breaks in therapists profile content

  a {
    color: var(--colorV2-darkest-green);
    text-decoration: underline;

    &:hover,
    &:focus {
      color: var(--colorV2-dark-green);
    }
  }

  @include mobile-all {
    font-size: var(--font-size-16);
    line-height: var(--line-height-3);
  }
}
