@import "@styles/variables";

.linkContainer {
  &:hover {
    .link,
    .linkIcon {
      color: var(--colorV2-dark-green);
      text-decoration-color: var(--colorV2-dark-green);
      cursor: pointer;
    }
  }
}

.link,
.linkIcon {
  color: var(--colorV2-black);
  text-decoration-color: var(--colorV2-black);
  text-decoration: underline;
  transition: color 300ms ease;

  &:hover {
    color: var(--colorV2-dark-green);
    text-decoration-color: var(--colorV2-dark-green);
    cursor: pointer;
  }
}

.linkIcon {
  margin-top: 2px;

  &.hasSmallMarginLeft {
    margin-left: -2px;
  }
}
