.DropzoneUploadMessage {
  &__container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: right;

    &:hover {
      cursor: pointer;
    }
  }

  &__button {
    background-color: var(--colorV2-grey-light);
    border-color: var(--black) !important;
    color: var(--black);
    padding: 0 12px !important;

    &:hover {
      background-color: var(--colorV2-grey-light-medium);
      border-color: var(--black) !important;
    }
  }
}
