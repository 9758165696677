.availabilityWeekday {
  font-size: var(--font-size-16);
  font-weight: 400;
  color: var(--colorV2-black);
  text-transform: uppercase;
}

.availabilityDate {
  font-size: var(--font-size-16);
  font-weight: 500;
  color: var(--colorV2-black);
}

.availabilityTimeWrapper {
  overflow-x: auto;
}

.availabilityItem {
  font-weight: 400;
  color: var(--colorV2-dark-green);
  background-color: var(--colorV2-grey-light);
  border: 2px solid var(--colorV2-grey-light);
  padding: 0 21px;
  height: 36px;
  width: 86px;
  transition:
    color,
    background-color 300ms ease;

  &.isHidden {
    display: none;
  }

  &:hover {
    font-weight: 500;
    color: var(--colorV2-black);
    background-color: var(--colorV2-light-green);
    border-color: var(--colorV2-grey-medium-dark);
  }

  &:focus {
    border-color: var(--colorV2-grey-dark);
  }
}
