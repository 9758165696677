@import "@styles/variables";

.container {
  color: var(--colorV2-black);
}

.card {
  border-radius: 20px;
  transition: all 300ms ease;

  &::before {
    background-color: var(--colorV2-beige);
  }

  &::before,
  &::after {
    border-radius: 20px;
  }
}

.cardImage {
  border-bottom: 1px solid var(--colorV2-grey-medium-dark);
}

.badge {
  background-color: var(--white);
  border: 1px solid var(--colorV2-grey-medium-dark);
  color: var(--colorV2-black);
  font-size: var(--font-size-14);
  font-weight: 400;
  padding: 5px 10px;
}
