@import "./_variables";
@import "./placeholders";

// _mixins.scss
@mixin mobile-portrait {
  @media (max-width: $mobile-landscape - 1px) {
    @content;
  }
}

@mixin mobile-landscape {
  @media (min-width: $mobile-landscape) and (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin mobile-all {
  @media (max-width: $tablet - 1px) {
    @content;
  }
}

@mixin non-desktop {
  @media (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $tablet) and (max-width: $desktop - 1px) {
    @content;
  }
}

@mixin tablet-and-up {
  @media (min-width: $tablet) {
    @content;
  }
}

@mixin desktop-all {
  @media (min-width: $desktop) {
    @content;
  }
}

@mixin desktop-small {
  @media (min-width: $desktop) and (max-width: $desktop-wide - 1px) {
    @content;
  }
}

@mixin desktop-wide {
  @media (min-width: $desktop-wide) {
    @content;
  }
}

@mixin webkit-line-clamp($line-count) {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line-count;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin card-anchor {
  text-decoration: inherit;
  color: inherit;
  cursor: pointer;

  :visited {
    text-decoration: inherit;
    color: inherit;
  }
}

@mixin truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin font-style-heading-m {
  font-weight: var(--font-weight-regular);
  font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
  line-height: var(--heading-line-height-6);
  letter-spacing: calc(var(--letter-spacing-6) + var(--heading-letter-spacing));

  @include mobile-all {
    font-size: calc(var(--font-size-4) * var(--heading-font-size-adjust));
    line-height: var(--heading-line-height-4);
    letter-spacing: calc(
      var(--letter-spacing-4) + var(--heading-letter-spacing)
    );
  }
}

@mixin font-style-heading-l {
  font-weight: var(--font-weight-regular);
  font-size: calc(var(--font-size-7) * var(--heading-font-size-adjust));
  line-height: var(--heading-line-height-7);
  letter-spacing: calc(var(--letter-spacing-7) + var(--heading-letter-spacing));

  @include mobile-all {
    font-size: calc(var(--font-size-5) * var(--heading-font-size-adjust));
    line-height: var(--heading-line-height-5);
    letter-spacing: calc(
      var(--letter-spacing-5) + var(--heading-letter-spacing)
    );
  }
}

@mixin font-style-heading-xl {
  font-weight: var(--font-weight-regular);
  font-size: calc(var(--font-size-8) * var(--heading-font-size-adjust));
  line-height: var(--heading-line-height-8);
  letter-spacing: calc(var(--letter-spacing-8) + var(--heading-letter-spacing));

  @include mobile-all {
    font-size: calc(var(--font-size-6) * var(--heading-font-size-adjust));
    line-height: var(--heading-line-height-6);
    letter-spacing: calc(
      var(--letter-spacing-6) + var(--heading-letter-spacing)
    );
  }
}

@mixin font-style-body-m {
  font-weight: var(--font-weight-regular);
  font-size: var(--font-size-3);
  line-height: var(--line-height-3);
  letter-spacing: var(--letter-spacing-3);

  @include mobile-all {
    font-size: var(--font-size-2);
    line-height: var(--line-height-2);
    letter-spacing: var(--letter-spacing-2);
  }
}

@mixin fullScreen {
  min-height: 100vh;
  height: 100%;
  width: 100%;
}

@mixin genericHtmlStyling {
  h1,
  h2,
  h3 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  h1 {
    @include font-style-heading-xl;
  }

  h2 {
    @include font-style-heading-l;
  }

  h3 {
    @include font-style-heading-m;
  }

  p {
    @include font-style-body-m;
    margin-bottom: 8px;
  }

  strong {
    font-weight: var(--font-weight-medium);
  }

  ul {
    @include font-style-body-m;
    margin-bottom: 1rem;

    @include mobile-all {
      padding-left: 0;
      width: fit-content;
      max-width: 90%;
      text-align: start;
    }
  }

  a {
    color: var(--colorV2-darkest-green);
    text-decoration: underline;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    word-break: break-all; // Add this line

    &:hover,
    &:focus {
      color: var(--colorV2-dark-green);
    }
  }

  > :first-child {
    margin-top: 0;
  }

  b {
    font-weight: var(--font-weight-medium);
  }
}
